<template>
  <div>
    <!-- file cargado -->
    <div v-if="fileUploaded.state" class="uploaded-file mb-2">
      <div class="d-flex justify-content-end">
        <i class="fas fa-check-circle text-success mr-2 mb-2"></i>
        <h4>Archivo PDF subido</h4>
      </div>
      <object
        :data="fileUploaded.file_url"
        width="100%"
        height="500"
        v-if="fileUploaded.file_url"
        class="text-white-50"
      >
        Tu navegador no admite la visualización de archivos PDF. Puedes
        <a
          class="text-warning pointer"
          :href="fileUploaded.file_url"
          target="_blank"
          download
          >descargarlo aquí</a
        >
        en su lugar.
      </object>
    </div>

    <!-- cargar file -->
    <div class="file-upload-container">
      <label
        for="fileInput"
        class="file-upload-label"
        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
        @drop="onFileDrop"
      >
        <div class="upload-icon">
          <i class="fas fa-cloud-upload-alt"></i>
        </div>
        <div class="upload-text">
          <span>Subir un archivo PDF</span>
          <span class="small-text">(Haga clic o arrastre y suelte)</span>
        </div>
      </label>
      <input
        type="file"
        id="fileInput"
        @change="onFileChange"
        ref="fileInput"
        accept=".pdf"
        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
        @drop="onFileDrop"
      />
      <div class="file-upload-preview" v-if="selectedFile">
        <h3 class="preview-title">Archivo Seleccionado:</h3>
        <div class="file-info">
          <p><strong>Nombre del Archivo:</strong> {{ selectedFile.name }}</p>
          <p><strong>Tipo de Archivo:</strong> {{ selectedFile.type }}</p>
          <p>
            <strong>Tamaño del Archivo:</strong>
            {{ formatFileSize(selectedFile.size) }}
          </p>
        </div>
        <div v-if="isPdf" class="pdf-preview">
          <object
            :data="previewUrl"
            type="application/pdf"
            width="100%"
            height="500"
            class="text-white-50"
          >
            Tu navegador no admite la visualización de archivos PDF. Puedes
            <a
              class="text-warning pointer"
              :href="previewUrl"
              target="_blank"
              download
              >descargarlo aquí</a
            >
            en su lugar.
          </object>
        </div>
        <div v-else>
          <p class="text-white-50">
            No se puede previsualizar el archivo seleccionado. Debe ser un
            archivo PDF.
          </p>
        </div>
        <button v-if="isPdf" @click="sendFile" class="send-button">
          {{ isSendFile ? "Subiendo..." : "Subir PDF" }}
          <i v-if="isSendFile" class="fas fa-spinner fa-spin-pulse"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const Swal = require("sweetalert2");
export default {
  props: {
    data: Array,
    item: Object,
    asistente: Object,
    folders: String,
  },
  data() {
    return {
      selectedFile: null,
      previewUrl: null,
      isSendFile: null,
      fileUploaded: {
        state: false,
        file_url: null,
      },
    };
  },
  mounted() {
    let item_data = this.data.find((item) => item.id === this.item.id);

    if (this.asistente) {
      if (item_data.estadoUploadCertificado != 0) {
        this.fileUploaded.state = true;
        this.fileUploaded.file_url = `https://www.helexiumfiles.com/${this.folders.replace(
          /\|/g,
          "/"
        )}/${this.item.id}/${
          this.asistente.numeroDocumento
        }.pdf?${Math.random()}`;
      }
    } else if (item_data.estadoUploadFiles != 0) {
      this.fileUploaded.state = true;
      this.fileUploaded.file_url = `https://www.helexiumfiles.com/${this.folders.replace(
        /\|/g,
        "/"
      )}/${this.item.id}/${this.item.numeroContrato}.pdf?${Math.random()}`;
    }

    console.log(item_data);
    console.log("fileUploaded", this.fileUploaded);
  },
  computed: {
    isPdf() {
      return this.selectedFile.type === "application/pdf";
    },
  },
  methods: {
    onFileChange() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0];

      if (file) {
        this.selectedFile = file;
        console.log(this.selectedFile, this.data, this.item);
        if (this.isPdf) {
          this.previewUrl = URL.createObjectURL(file);
        }
      }
    },
    formatFileSize(size) {
      if (size === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    onFileDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.selectedFile = file;
      console.log(this.selectedFile, this.data, this.item);
      if (this.isPdf) {
        this.previewUrl = URL.createObjectURL(file);
      }
    },
    async sendFile() {
      console.log(this.selectedFile, this.data, this.item);
      try {
        this.isSendFile = true;
        let url = `.|site1|${this.folders}|${this.item.id}`;

        // Crear un nuevo objeto File con el nombre deseado
        const modifiedFile = new File(
          [this.selectedFile],
          this.asistente
            ? this.asistente.numeroDocumento + ".pdf"
            : this.item.numeroContrato + ".pdf"
        );

        let uploadFile = await this.$store.dispatch("onFileCustom", {
          filename: modifiedFile.name,
          file: modifiedFile,
          url: url,
        });

        if (uploadFile) {
          let res = await this.$store.dispatch("hl_get", {
            path: this.asistente
              ? `PlanCapacitacionProgreso/UpdateEstadoUploadCertificado/` +
                this.item.id +
                "?isTrue=true"
              : `PlanRegistroCapacitaciones/ToggleEstadoUploadFiles/` +
                this.item.id +
                "?isTrue=true",
          });

          if (res.success) {
            console.log(res);
            if (this.asistente) {
              this.item.estadoUploadCertificado = 1;
              this.data.find(
                (item) => item.id === this.item.id
              ).estadoUploadCertificado = 1;
            } else {
              this.item.estadoUploadFiles = 1;
              this.data.find(
                (item) => item.id === this.item.id
              ).estadoUploadFiles = 1;
            }
            this.isSendFile = false;
            this.selectedFile = null;
            this.fileUploaded.state = true;
            this.fileUploaded.file_url = `https://www.helexiumfiles.com/${this.folders.replace(
              /\|/g,
              "/"
            )}/${this.item.id}/${
              this.asistente
                ? this.asistente.numeroDocumento
                : this.item.numeroContrato
            }.pdf?${Math.random()}`;

            Swal.fire({
              title: "Listo!",
              text: "Se ha subido el archivo.",
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        }
      } catch (error) {
        this.isSendFile = false;
        console.log(error);
        return null;
      }
    },
  },
};
</script>

<style scoped>
object {
  border-radius: 10px;
}
.file-upload-container {
  text-align: center;
}

.file-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4788ff;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  user-select: none;
  border: 2px dashed transparent;
  transition: all 0.3s ease-in-out;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-upload-label.dragover {
  border-color: #4788ff;
}

.upload-icon {
  background-color: #3498db;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.upload-icon i {
  font-size: 36px;
  color: #fff;
}

.upload-text {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.small-text {
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
}

.file-upload-label:hover {
  background-color: #2980b9;
}

#fileInput {
  display: none;
}

.file-upload-preview {
  border: 2px dotted #2980b9;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #0c1a30;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.preview-title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
}

.file-info {
  text-align: left;
}

.pdf-preview {
  margin-top: 20px;
}

.send-button {
  background-color: #4788ff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  margin-top: 10px;
}

.send-button:hover {
  background-color: #2980b9;
}
</style>
